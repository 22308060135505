import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const FAQ = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center">
              <Title color="light">
                Frequently
                <br className="d-none d-sm-block" /> Asked Questions
              </Title>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col lg="6" className="mt-5">
            <FaqCard title="Why do I need an online store?">In the age of social media, if you are not promoting your products on Facebook and Whatsapp you are missing out. </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Is MiniDukan free?">Yes, MiniDukan is on a mission to help small businesses by providing them free services. We don’t take any commissions on orders</FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Can I use my own domain name?">Yes, you will get a personal domain name when you sign up for MiniDukan. </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="What type of products can I sell on MiniDukan?">You can use MiniDukan to sell any kind of product, from clothing to groceries to electronics.</FaqCard>
          </Col>
          <Col lg="12" className="">
            <Box className="text-center" pt={5}>
              <Text variant="small" color="lightShade">
                Didn’t find your answer?{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=+923306464385"
                  target="_blank"
                  css={`
                    color: ${({ theme }) => theme.colors.primary} !important;
                    `}
                  rel="noreferrer" 
                >
                  Contact us here
                </a>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FAQ;
