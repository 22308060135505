import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import PlayButton from "../../components/PlayButton/PlayButton";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const CTA = () => (
  <>
    <SectionStyled className="position-relative">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title variant="section">Start your Dukan Now!</Title>

              <Text>Download the app and get your dukan started in less than 20 seconds.</Text>
              <ButtonContainer>
                <PlayButton />
              </ButtonContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default CTA;
