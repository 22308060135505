import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const ShapeCard1 = styled(Box)`
  width: 220px;
  position: absolute;
  top: 70%;
  left: 13%;
  transform: scale(0.85);
  @media ${device.sm} {
    width: 290px;
    left: 13;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 13%;
  }
`;

const ShapeCard2 = styled(Box)`
  width: 305px;
  position: absolute;
  top: 45%;
  left: 23%;
  transform: scale(0.85);
  @media ${device.sm} {
    left: 23%;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 40%;
  }
`;



const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section>
      <Container>
        <Row className="align-items-center">
          <Col md="12" lg="6" className="order-lg-2">
            <div className="position-relative pl-lg-5">
              <div
                className="pl-lg-4 text-center text-lg-right"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <StaticImage
                  src="../../assets/image/png/AddProductsBG-optimized.png"
                  alt="Products and Add Products Screens"
                  className="img-fluid"
                  placeholder="blurred"
                />
              </div>

              <ShapeCard1
                p="18px"
                borderRadius={0}
                className="d-flex align-items-start"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <StaticImage src="../../assets/image/png/AddProductButton-optimized.png" alt="Add Product Button" className="img-fluid" />
              </ShapeCard1>
              <ShapeCard2
                p="18px"
                borderRadius={0}
                className="d-flex align-items-start"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <StaticImage src="../../assets/image/png/AddProductZoomedEntry-optimized.png" alt="Adding a Product to Store field" className="img-fluid" />
              </ShapeCard2>

            </div>
          </Col>
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <Box>
                <Title>Quickly Add Products</Title>
                <Text mb={4}>
                  Its fast and easy add your products. Take a picture, set a name, set a price and add your product! You can also add your products to different categories, letting you easily build your online store.
                </Text>

              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
