import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

import { Title, Section, Box, Text } from "../../components/Core";
import PlayButton from "../../components/PlayButton/PlayButton";

import svgHeroShape from "../../assets/image/svg/hero-shape-svg.svg";


const ShapeTopLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;


const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;



const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative">
        <div className="pt-5"></div>
        <ShapeTopLeft>
          <img src={svgHeroShape} alt="" className="img-fluid" />
        </ShapeTopLeft>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" md="8" sm="9" className="order-lg-2">
              <div className="text-center text-lg-right position-relative">
                <StaticImage
                  src="../../assets/image/png/ProductPictureStore-optimized-smaller.png"
                  alt="MiniDukan Store and Login Screen picture"
                  className="hero-main-dukan first-hero"
                  loading="eager"
                  placeholder="tracedSVG"
                />
                {/* <div
                    className="img-main "
                    data-aos="fade-left"
                    data-aos-duration="500"
                    data-aos-once="true"
                    data-aos-delay="200"
                    >
                    <StaticImage
                    src="../../assets/image/png/MiniDukanShop.png"
                    alt="MiniDukan Store and Login Screen picture"
                    className="hero-main-dukan second-hero"
                    loading="eager"
                    />
                    </div> */}
              </div>
            </Col>
            <Col lg="7" className="order-lg-1">
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <Title variant="hero">
                  Start your own online dukan
                </Title>
                <Text mb={4}>
                  Create your MiniDukan in 20 seconds and easily share it with your customers.
                </Text>
                <div className="d-flex flex-column align-items-start">
                  <ButtonContainer>
                    <PlayButton />
                  </ButtonContainer>
                </div>
              </Box>
            </Col>
          </Row>

        </Container>
      </Section>
    </>
  );
};

export default Hero;
