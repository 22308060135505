import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content1 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-2">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <StaticImage src="../../assets/image/png/ManageOrders-optimized.png" alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>
                  Manage all{" "}
                  <br className="d-none d-sm-block d-md-none d-xl-block" /> your
                  orders in one place
                </Title>
                <Text>Get notifications for all your orders and never miss a sale!</Text>
                <WidgetContainer mt={5}>
                  <Widget title="Accept or Reject Orders" mb={4}>
                    Accept your orders with one tap, update your delivery fee and keep your customers automatically informed!
                  </Widget>
                  <Widget title="Directly share your bill" mb={4}>
                    With your customers over WhatsApp
                  </Widget>
                  <Widget title="SMS your buyers">
                    Free SMS notifications help increase your sales and ensure your orders are delivered smoothly
                  </Widget>
                </WidgetContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
