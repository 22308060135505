import React from "react";
import Hero from "../sections/landing1/Hero";
import Feature from "../sections/landing1/Feature";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Content3 from "../sections/landing6/Content1";
import FAQs from "../sections/landing3/FAQ";
import CTA from "../sections/landing6/CTA";
import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Feature />
        <Content1 />
        <Content2 />
        <Content3 />
        <FAQs />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
